<template>
   <b-modal
      v-model="isModalOpen"
      size="lg"
      :title="modalTitle"
      centered
      scrollable
      title-class="font-22"
      hide-footer
      @hide="onModalHide"
  >
    <b-form @submit.prevent="handleSubmit">
      <b-form-group
        label="Service"
        label-cols-md="2"
        label-align="right"
        label-for="service"
        class="required"
      >
        <multiselect
          id="service"
          v-model="form.service"
          :options="services"
          :disabled="!!serviceId"
          searchable
          track-by="id"
          label="title"
          class="app-multiselect"
        />
      </b-form-group>
      <b-form-group
        label="Employees"
        label-cols-md="2"
        label-align="right"
        label-for="employees"
        class="required"
      >
        <multiselect
          id="employees"
          v-model="form.employees"
          :options="employees"
          :disabled="!isCreateMode"
          multiple
          searchable
          track-by="uid"
          label="name"
          class="app-multiselect"
        />
      </b-form-group>
      <b-form-group
        label="Api Key"
        label-cols-md="2"
        label-align="right"
        label-for="api-key"
        class="required"
      >
        <b-form-input
          id="api-key"
          v-model="form.apiKey"
        />
      </b-form-group>
      <b-form-group
        label="Description"
        label-cols-md="2"
        label-align="right"
        label-for="description"
        class="required"
      >
        <b-form-textarea
          id="description"
          v-model="form.description"
        />
      </b-form-group>
      <b-form-group
        label="Creation Date"
        label-cols-md="2"
        label-align="right"
        label-for="creation-date"
      >
        <date-picker
          v-model="form.creationDate"
          valueType="YYYY-MM-DD"
          :input-attr="{ id: 'creation-date' }"
        />
      </b-form-group>
      <b-form-group
        label="Expiration Date"
        label-cols-md="2"
        label-align="right"
        label-for="expiration-date"
      >
        <date-picker
          v-model="form.expirationDate"
          valueType="YYYY-MM-DD"
          :input-attr="{ id: 'creation-date' }"
        />
      </b-form-group>
      <div class="d-flex justify-content-end">
        <b-button
          type="submit"
          variant="success"
          :disabled="isSubmitButtonDisabled"
        >
          {{ submitButtonText }}
        </b-button>
      </div>
    </b-form>
    <loader :show="loading" position="absolute" small />
  </b-modal>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import Loader from '@/components/Loader.vue';

export default {
  components: {
    Loader,
    DatePicker,
  },
  props: {
    value: Boolean,
    initialData: Object,
    serviceId: Number
  },
  data() {
    return {
      form: {
        apiKey: '',
        service: null,
        employees: [],
        description: '',
        creationDate: null,
        expirationDate: null
      },
      loading: false,
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      const payload = {
        service_id: this.form.service.id,
        api_key: this.form.apiKey,
        description: this.form.description,
        creation_date: this.form.creationDate,
        expiration_date: this.form.expirationDate,
      }
      if (this.isCreateMode) {
        payload.employees = this.form.employees.map(item => item.uid);
        await this.$store.dispatch('apiKeys/createApiKey', payload);
      } else {
        await this.$store.dispatch('apiKeys/updateApiKey', {
          id: this.initialData.id,
          data: payload
        });
      }
      this.$emit('submit-success');
      this.loading = false;
    },
    onModalHide() {
      this.$emit('hide');
      this.form.apiKey = '';
      this.form.service = null;
      this.form.employees = [];
      this.form.description  = '';
      this.form.creationDate = null;
      this.form.expirationDate = null;
    }
  },
  computed: {
    services() {
      return this.$store.state.services.list;
    },
    employees() {
      return this.$store.state.employees.list;
    },
    isSubmitButtonDisabled() {
      const { apiKey, description, service, employees } = this.form;
      return !(apiKey && service && description && employees.length);
    },
    isCreateMode() {
      return !this.initialData;
    },
    submitButtonText() {
      return this.isCreateMode ? 'Create' : 'Save';
    },
    modalTitle() {
      return this.isCreateMode ? 'Create API Key' : 'Edit API Key';
    },
    isModalOpen: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      }
    }
  },
  watch: {
    initialData(data) {
      this.form.apiKey = data?.api_key ?? '';
      if (!this.serviceId) {
        this.form.service = this.services
          .find(service => service.id === data?.service_id) ?? null;
      }
      this.form.employees = this.employees
        .filter(item => (data?.employees ?? []).some(employee => employee.uid === item.uid));
      this.form.description = data?.description ?? '';
      this.form.creationDate = data?.creation_date ?? null;
      this.form.expirationDate = data?.expiration_date ?? null;
    }
  },
  async mounted() {
    this.$store.dispatch('employees/getEmployees');
    await this.$store.dispatch('services/getServices');
    if (this.serviceId) {
      this.form.service = this.services.find(service => service.id === this.serviceId);
    }
  },
}
</script>

<style lang="scss" scoped>
:deep(.modal-body) {
  position: static;
}
</style>