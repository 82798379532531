export default {
  data() {
    return {
      fields: [
        { key: 'service.title', label: 'Service Name', thStyle: { minWidth: '140px' } },
        { key: 'employees', label: 'Employees' },
        { key: 'api_key', label: 'API Key' },
        { key: 'description', label: 'Description' },
        { key: 'creation_date', label: 'Creation Date', thStyle: { minWidth: '140px', width: '140px', maxWidth: '140px' } },
        { key: 'expiration_date', label: 'Expiration Date', thStyle: { minWidth: '140px', width: '140px', maxWidth: '140px' } },
        { key: 'actions', label: '', thStyle: { minWidth: '100px', width: '100px', maxWidth: '100px' } }
      ],
      loading: false,
      editableKey: null,
      isModalVisible: false,
    }
  },
  methods: {
    async fetchList() {},
    onModalHide() {
      this.editableKey = null;
    },
    onAddButtonClick() {
      this.isModalVisible = true;
    },
    async onSubmitSuccess() {
      this.isModalVisible = false;
      this.loading = true;
      await this.fetchList();
      this.loading = false;
    },
    onEditIconClick(key) {
      this.editableKey = key;
      this.isModalVisible = true;
    },
    onDeleteIconClick(id) {
      this.$bvModal.msgBoxConfirm('Delete this key?', {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(async (value) => {
          if (value) {
            this.loading = true;
            await this.$store.dispatch('apiKeys/deleteApiKey', id);
            await this.fetchList();
            this.loading = false;
          }
        })
    }
  }
}